<template>
  <div class="wrapper my-5">
    <div class="container" v-if="!loggedIn">
      <div class="login-form text-center">
        <h1 class="mb-4">Please Login</h1>
        <b-alert variant="danger" show v-if="loginError"
          >Password incorrect!</b-alert
        >
        <div class="mb-3">
          <b-form-input
            type="password"
            v-model="password"
            placeholder="Enter the password"
            :state="paswordState"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            Must be at least 6 characters
          </b-form-invalid-feedback>
        </div>
        <b-button variant="success" @click="login" :disabled="!paswordState"
          >Login</b-button
        >
      </div>
    </div>
    <div class="container admin" v-if="loggedIn">
      <h1 class="text-center mb-4">Order Attempts List</h1>
      <b-table striped hover :items="data" :fields="fields"></b-table>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    paswordState() {
      if (this.password != null) {
        return this.password.length > 6 ? true : false;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      loggedIn: false,
      fields: {
        0: {
          label: "Date",
          sortable: true
        },
        2: {
          label: "Order ID",
          sortable: true
        },
        3: {
          label: "Plan",
          sortable: true
        },
        5: {
          label: "price",
          sortable: true
        },
        6: {
          label: "name",
          sortable: false
        },
        7: {
          label: "email",
          sortable: false
        },
        8: {
          label: "Is Success",
          sortable: true
        },
        9: {
          label: "Error",
          sortable: false
        },
        10: {
          label: "Card"
        }
      },
      data: null,
      password: null,
      hash: null,
      loginError: null
    };
  },
  async mounted() {},
  methods: {
    async getData() {
      await this.axios
        .post("https://clubejecutivo.aicad.es/spain/payment/index.php", {
          password: this.hash
        })
        .then(response => {
          console.log(response.status);
          if (response.status === 200) {
            this.data = response.data;
            this.loggedIn = true;
          } else if (response.status === 203) {
            this.loggedIn = false;
            this.loginError = true;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    login() {
      var bcrypt = require("bcryptjs");
      var salt = "$2a$07$R.UGfy83M.JquYR3tA6quf$";
      this.hash = bcrypt.hashSync(this.password, salt);

      this.getData();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/master-postgrado/bootstrap.min.css";
@import "../../assets/css/master-postgrado/main.min.css";
.admin {
  font-size: 1.4rem;
}

.login-form {
  margin: auto;
  width: 50%;

  @media screen and (max-width: 767px) {
    & {
      width: 90%;
    }
  }

  input,
  button,
  .alert-danger {
    font-size: 1.4rem;
  }
}

.invalid-feedback {
  font-size: 1.2rem;
  text-align: left;
}
</style>
